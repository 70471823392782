<template>
  <st-title
    :navItems="[
      {
        name: $t('studio.lnb.common_2depth_member_management'),
        url: 'members'
      },
      {
        name: $t('studio.member_manage.role_manage_btn'),
        url: `members/roles`
      },
      {
        name: isEditMode
          ? $t('studio.member_manage.role_manage_details_title')
          : $t('studio.member_manage.role_manage_create_role_btn')
      }
    ]"
    :title="
      isEditMode
        ? $t('studio.member_manage.role_manage_details_title')
        : $t('studio.member_manage.role_manage_create_role_btn')
    "
  />
  <div class="flex gap-24 mt-40">
    <div class="flex-1">
      <st-box>
        <st-form-title
          :formTitle="$t('studio.member_manage.role_manage_create_role_msg1')"
          required
        />
        <input-text
          :rules="{
            required: {
              message: $t('studio.member_manage.role_manage_create_role_alert1')
            },
            max_length: {
              max: 50
            }
          }"
          maxLength="50"
          name="name"
          size="lg"
          variant="outline"
          :placeholder="$t('studio.member_manage.role_manage_create_role_place_holder')"
          :disabled="currentRole?.isBasicRole"
        />
        <st-form-title
          :formTitle="$t('studio.member_manage.role_manage_create_role_msg2')"
          required
          class="mt-32"
        />
        <p class="text-xs leading-xs text-placeholder font-regular mt-4">
          {{ $t('studio.member_manage.role_manage_create_role_msg3') }}
        </p>
        <m-table class="mt-8">
          <colgroup>
            <col style="width: auto" />
            <col style="width: 9.6rem" />
            <col style="width: 9.6rem" />
          </colgroup>
          <thead>
            <tr>
              <st-th
                v-model:checkBoxValue="isCheckedAll"
                :modelValue:checkBoxDisabled="currentRole?.isBasicRole"
                :checkboxUse="!(isEditMode && currentRole?.isBasicRole)"
                :thTitle="$t('studio.member_manage.role_manage_list_by_menu')"
                @onCheckboxUpdate="handleCheckAll"
              />
              <st-th
                aCenter
                :thTitle="$t('studio.member_manage.role_manage_create_role_perm_view_header2')"
              />
              <st-th
                aCenter
                :thTitle="$t('studio.member_manage.role_manage_create_role_perm_edit_header3')"
              />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="3">
                <div class="flex flex-col gap-8 mt-8">
                  <st-folding
                    v-for="role in currentRole?.roles"
                    :key="`${role.name}`"
                    roleAdminType
                    :name="$t(getNameRoleAuthorityById(role.name))"
                    :isDisabled="role.childRoles?.length === 0"
                    :viewableCheck="viewableCheck(role)"
                    :editableCheck="editableCheck(role)"
                    :isEditableDisabled="currentRole?.isBasicRole || !viewableCheck(role)"
                    :isEditable="!(currentRole?.isBasicRole && isEditMode)"
                    :projectSubDescription="
                      role.name === RELEASE_PLAN && !currentRole?.isBasicRole
                        ? $t('studio.member_manage.role_manage_create_role_msg4')
                        : ''
                    "
                    isOpenDetail
                    @onViewableCheck="handleViewableUpdate(role, $event)"
                    @onEditableCheck="handleEditableUpdate(role, $event)"
                  >
                    <div v-if="role.childRoles && role.childRoles.length > 0" class="mb-8 ml-28">
                      <m-table>
                        <colgroup>
                          <col style="width: auto" />
                          <col style="width: 9.6rem" />
                          <col style="width: 9.6rem" />
                        </colgroup>
                        <thead class="sr-only">
                          <tr>
                            <st-th
                              :thTitle="
                                $t('studio.member_manage.role_manage_create_role_menu_header1')
                              "
                            />
                            <st-th
                              :thTitle="
                                $t('studio.gnb.group_myinfo.my_group.detailed_group_per_view')
                              "
                            />
                            <st-th
                              :thTitle="
                                $t('studio.gnb.group_myinfo.my_group.detailed_group_per_edit')
                              "
                            />
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(subRole, subRoleIndex) in role.childRoles"
                            :key="subRole.roleAuthorityId"
                          >
                            <st-td aLeft class="!py-[.7rem]">
                              <span class="text-md text-on-surface-elevation-3">{{
                                $t(getNameRoleAuthorityById(subRole.roleAuthorityId))
                              }}</span>
                            </st-td>
                            <template v-if="isEditMode && currentRole?.isBasicRole">
                              <st-td onlyButton>
                                <s-icon
                                  v-if="subRole.viewable"
                                  icon="ic-v2-control-check-bold-line"
                                  size="3xl"
                                  class="text-on-surface-elevation-1 inline-flex"
                                />
                                <button
                                  v-else
                                  type="button"
                                  class="inline-flex disabled:text-disabled-variant-1"
                                  disabled
                                >
                                  <s-icon icon="ic-v2-control-check-bold-line" size="3xl" />
                                </button>
                              </st-td>
                              <st-td v-if="currentRole.name === ROLE_MARKETER" onlyButton>
                                <s-icon
                                  v-if="subRole.editable"
                                  icon="ic-v2-control-check-bold-line"
                                  size="3xl"
                                  class="text-on-surface-elevation-1 inline-flex"
                                />

                                <button
                                  v-else
                                  type="button"
                                  class="inline-flex disabled:text-disabled-variant-1"
                                  disabled
                                >
                                  <s-icon icon="ic-v2-control-check-bold-line" size="3xl" />
                                </button>
                              </st-td>
                              <st-td v-else onlyButton>
                                <span
                                  v-if="
                                    subRole.roleAuthorityId === ROLE_ID.PRODUCT_STATISTICS ||
                                      subRole.roleAuthorityId === ROLE_ID.PRODUCT_BILL_SETTLEMENT
                                  "
                                  class="text-placeholder font-medium"
                                >-</span>
                                <s-icon
                                  v-else-if="subRole.editable"
                                  icon="ic-v2-control-check-bold-line"
                                  size="3xl"
                                  class="text-on-surface-elevation-1 inline-flex"
                                />
                                <button
                                  v-else
                                  type="button"
                                  class="inline-flex disabled:text-disabled-variant-1"
                                  disabled
                                >
                                  <s-icon icon="ic-v2-control-check-bold-line" size="3xl" />
                                </button>
                              </st-td>
                            </template>
                            <template v-else>
                              <st-td onlyButton>
                                <div class="h-16">
                                  <checkbox
                                    :id="`viewable-${subRoleIndex}`"
                                    v-model="subRole.viewable"
                                    :disabled="currentRole?.isBasicRole"
                                    :options="{
                                      size: 'sm',
                                      align: 'middle'
                                    }"
                                    @update:modelValue="
                                      handleChildRoleViewableUpdate(subRole, $event)
                                    "
                                  />
                                </div>
                              </st-td>
                              <st-td onlyButton>
                                <div class="h-16">
                                  <span
                                    v-if="
                                      subRole.roleAuthorityId === ROLE_ID.PRODUCT_STATISTICS ||
                                        subRole.roleAuthorityId === ROLE_ID.PRODUCT_BILL_SETTLEMENT
                                    "
                                    class="text-placeholder font-medium mr-8"
                                  >-</span>
                                  <checkbox
                                    v-else
                                    :id="`editable-${subRoleIndex}`"
                                    v-model="subRole.editable"
                                    :disabled="currentRole?.isBasicRole || !subRole.viewable"
                                    :options="{
                                      size: 'sm',
                                      align: 'middle'
                                    }"
                                    @update:modelValue="handleChildRoleEditableUpdate"
                                  />
                                </div>
                              </st-td>
                            </template>
                          </tr>
                        </tbody>
                      </m-table>
                    </div>
                  </st-folding>
                </div>
              </td>
            </tr>
          </tbody>
        </m-table>
      </st-box>
      <div class="mt-40 flex justify-center gap-16">
        <s-button
          v-if="isEditMode && !currentRole?.isBasicRole"
          variant="outline"
          size="lg"
          class="!min-w-160"
          @click="onDelete"
        >
          {{ $t('studio.member_manage.role_manage_details_delete_btn') }}
        </s-button>
        <s-button
          v-if="!currentRole?.isBasicRole"
          variant="primary"
          :isDisabled="currentRole?.isBasicRole"
          size="lg"
          class="!min-w-160"
          @click="!isEditMode ? onCreate() : onEdit()"
        >
          {{
            isEditMode
              ? $t('studio.member_manage.role_manage_details_save_btn')
              : $t('studio.member_manage.role_manage_create_role_register_btn')
          }}
        </s-button>
      </div>
    </div>
    <right-wing>
      <right-wing-item
        :rightWingTitle="$t('studio.member_manage.role_manage_create_role_msg6')"
        numberValue="01"
      >
        <safe-html as="p" :html="$t('studio.member_manage.role_manage_create_role_msg7')" />
      </right-wing-item>
      <right-wing-item
        v-if="isEditMode && currentRole?.isBasicRole"
        :rightWingTitle="$t('studio.member_manage.role_manage_details_msg1')"
        numberValue="02"
        class="mt-24"
      >
        <safe-html as="p" :html="$t('studio.member_manage.role_manage_details_msg2')" />
      </right-wing-item>
    </right-wing>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useForm, useSetFieldError } from 'vee-validate';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRouter } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import { createRoleApi, deleteRoleApi, fetchRoleDetailApi, modifyRoleApi } from '@/apis/member.api';
import RightWing from '@/components/app/right-wing/index.vue';
import RightWingItem from '@/components/app/right-wing/item.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StFolding from '@/components/common/st-folding.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import StTitle from '@/components/common/st-title.vue';
import MTable from '@/components/common/table/m-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTh from '@/components/common/table/st-th.vue';
import Checkbox from '@/components/validation/checkbox.vue';
import InputText from '@/components/validation/input-text.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showConfirm } from '@/composables/useDialog';
import { VIEW_MODE } from '@/constants/common.const';
import { INVALID_MEMBER_CODES } from '@/constants/error.const';
import {
  DEFAULT_ROLE,
  RELEASE_PLAN
} from '@/constants/member-role.const';
import { ROLE_ID, ROLE_MARKETER } from '@/constants/permission.const';
import { MEMBER_ROLES_PAGE_URL } from '@/constants/url.const';
import { RuleNames } from '@/enums/validation.enum';
import { createRoleFormMapper, roleFormModelMapper } from '@/mappers/member-mng.mapper';
import { useUserStore } from '@/stores/user.store';
import type {
  ChildRoleAuthority,
  CreateRoleRequest,
  RolesRequest
} from '@/types/role/role.request.type';
import { getBoolean, redirectTo, showConfirmLeaveDialog } from '@/utils/common.util';
import { getNameRoleAuthorityById, getRoleName } from '@/utils/permission.util';
import { generateErrorMsg } from '@/utils/validation.util';

definePageMeta({
  middleware: 'check-menu-writable-middleware'
});

const app = useApp();
const router = useRouter();

const userStore = useUserStore();

const { selectedGroupId } = storeToRefs(userStore);

const { t } = useI18n();

const { handleSubmit, setValues } = useForm({
  initialValues: {
    name: ''
  }
});

const setNameError = useSetFieldError('name');

const roleId = router.currentRoute.value.query.roleId?.toString();

const currentRole = ref<CreateRoleRequest>();
const isCheckLeave = ref<boolean>(true);

const isEditMode = computed(() => {
  return formType.value === VIEW_MODE.EDIT;
});

const formType = computed<string>(() => {
  return router.currentRoute.value.query.roleId ? VIEW_MODE.EDIT : VIEW_MODE.CREATE;
});

const isCheckedAll = computed(() => {
  const isAllRolesChecked = (currentRole.value?.roles ?? []).every((role: RolesRequest) => {
    if (role.childRoles && role.childRoles.length > 0) {
      return role.childRoles.every(
        (subRole: ChildRoleAuthority) => subRole.viewable && subRole.editable
      );
    }

    if (role.name === RELEASE_PLAN) {
      return role.viewable && role.editable;
    }

    return false;
  });

  return isAllRolesChecked;
});

const viewableCheck = (role: RolesRequest) => {
  if (role.childRoles && role.childRoles.length === 0) {
    return role.viewable;
  }

  return role.childRoles?.every((subRole: ChildRoleAuthority) => subRole.viewable);
};

const handleViewableUpdate = (role: RolesRequest, e: boolean) => {
  if (e && role.name === RELEASE_PLAN) {
    handleCheckAll(e);

    if (!currentRole.value) {
      return;
    }

    const releasePlanRole = (currentRole.value.roles ?? []).find(
      (r: RolesRequest) => r.name === RELEASE_PLAN
    );
    if (releasePlanRole) {
      releasePlanRole.editable = false;
    }

    return;
  }

  if (role.childRoles && role.childRoles.length === 0) {
    role.viewable = e;

    if (!e) {
      role.editable = e;
    }

    return;
  }

  (role.childRoles ?? []).forEach((r: ChildRoleAuthority) => {
    r.viewable = e;
  });

  if (!e) {
    handleEditableUpdate(role, e);
    setReleasePlanRole(e);
  }
};

const editableCheck = (role: RolesRequest) => {
  if (role.childRoles && role.childRoles.length === 0) {
    return role.editable;
  }

  return role.childRoles?.every((subRole: ChildRoleAuthority) => subRole.editable);
};

const handleEditableUpdate = (role: RolesRequest, e: boolean) => {
  if (!e && role.name !== RELEASE_PLAN) {
    setReleasePlanRole(e);
  }

  if (e && role.name === RELEASE_PLAN) {
    handleCheckAll(e);

    return;
  }

  if (role.childRoles && role.childRoles.length === 0) {
    role.editable = e;

    return;
  }

  (role.childRoles ?? []).forEach((r: ChildRoleAuthority) => {
    r.editable = e;
  });
};

const handleCheckAll = (e: boolean) => {
  (currentRole.value?.roles ?? []).forEach((role: RolesRequest) => {
    if (role.childRoles && role.childRoles.length === 0) {
      role.editable = e;
      role.viewable = e;

      return;
    }
    (role.childRoles ?? []).forEach((r: ChildRoleAuthority) => {
      r.editable = e;
      r.viewable = e;
    });
  });
};

const setReleasePlanRole = (e: boolean) => {
  if (!currentRole.value) {
    return;
  }

  const releasePlanRole = (currentRole.value.roles ?? []).find(
    (r: RolesRequest) => r.name === RELEASE_PLAN
  );
  if (releasePlanRole) {
    releasePlanRole.editable = e;
    releasePlanRole.viewable = e;
  }
};

const handleChildRoleViewableUpdate = (role: ChildRoleAuthority, e: boolean) => {
  if (!e) {
    role.editable = e;

    setReleasePlanRole(e);
  }
};

const handleChildRoleEditableUpdate = (e: boolean) => {
  if (!e) {
    setReleasePlanRole(e);
  }
};

const onCreate = handleSubmit(async (value: { name: string }) => {
  if (!currentRole.value) {
    return;
  }

  if (!(await app.checkMenuWritable())) {
    return;
  }

  try {
    currentRole.value.name = value.name;
    const data = roleFormModelMapper(currentRole.value);
    const res = await createRoleApi({ groupId: selectedGroupId.value, data });

    if (res.status === INVALID_MEMBER_CODES.MEMBER_ROLE_NAME_DUPLICATED) {
      setNameError(
        generateErrorMsg(
          'studio.member_manage.role_manage_create_role_val1',
          RuleNames.CHECK_DUPLICATE
        )
      );
      return;
    }
    await showAlert({
      severity: 'info',
      content: t('studio.member_manage.role_manage_create_role_register_alert_msg')
    });

    isCheckLeave.value = false;
    redirectTo(MEMBER_ROLES_PAGE_URL);
  } catch (error) {
    await showAlert({
      severity: 'info',
      content: t('studio.member_manage.role_manage_details_delete_step2_delete_alert2_msg'),
      isCancelButtonVisible: false
    });
  }
});

const onEdit = handleSubmit(async (value: { name: string }) => {
  if (!currentRole.value || !roleId) {
    return;
  }

  if (!(await app.checkMenuWritable())) {
    return;
  }

  try {
    currentRole.value.name = value.name;

    const data = roleFormModelMapper(currentRole.value);
    await modifyRoleApi({ groupId: selectedGroupId.value, roleId, data });

    await showAlert({
      severity: 'info',
      content: t('studio.member_manage.role_manage_details_save_alert_msg')
    });

    isCheckLeave.value = false;
    redirectTo(MEMBER_ROLES_PAGE_URL);
  } catch {
    await showAlert({
      severity: 'info',
      content: t('studio.member_manage.role_manage_details_delete_step2_delete_alert2_msg'),
      isCancelButtonVisible: false
    });
  }
});

const onDelete = async () => {
  if (!(await app.checkMenuWritable())) {
    return;
  }

  const result = await showConfirm({
    severity: 'info',
    content: t('studio.member_manage.role_manage_details_delete_step1_msg'),
    cancelLabel: t('studio.common.popup_case_cancel_btn'),
    confirmLabel: t('studio.member_manage.role_manage_details_delete_step1_delete_btn'),
    cancelVariant: 'outline',
    confirmVariant: 'red'
  });

  if (result) {
    if (!roleId) {
      return;
    }

    try {
      await deleteRoleApi({ groupId: selectedGroupId.value, roleId });

      await showAlert({
        severity: 'info',
        content: t('studio.member_manage.role_manage_details_delete_step2_delete_alert1_msg')
      });

      isCheckLeave.value = false;
      redirectTo(MEMBER_ROLES_PAGE_URL);
    } catch {
      await showAlert({
        severity: 'info',
        content: t('studio.member_manage.role_manage_details_delete_step2_delete_alert2_msg'),
        isCancelButtonVisible: false
      });
    }
  }
};

const init = async () => {
  // Edit mode
  if (isEditMode.value) {
    if (!roleId) {
      return;
    }

    const res = await fetchRoleDetailApi({
      groupId: selectedGroupId.value,
      roleNo: Number(roleId)
    });
    if (!res) {
      return;
    }

    const name = getBoolean(res.editableYn) ? res.roleName : t(getRoleName(res.roleName));
    setValues({
      name
    });

    currentRole.value = createRoleFormMapper(res);

    return;
  }

  // Create mode

  // DEFAULT ROLE
  currentRole.value = {
    name: '',
    roles: structuredClone(DEFAULT_ROLE),
    isBasicRole: false
  };

  setValues({
    name: currentRole.value?.name || ''
  });

  // DEFAULT ALL CHECK
  handleCheckAll(true);
};

init();

onBeforeRouteLeave(async () => {
  if (currentRole.value?.isBasicRole) {
    return;
  }

  if (isCheckLeave.value) {
    return await showConfirmLeaveDialog();
  }
});
</script>
